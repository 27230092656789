import ROUTE from './names'
import b2bRoutes from './routes/b2b-routes'
import assessmentRoutes from './routes/assessment-routes'

export default [
  ...b2bRoutes,
  ...assessmentRoutes,
  {
    path: '/:pathMatch(.*)*',
    redirect: {
      name: ROUTE.B2B_PARTNERS,
      params: {},
    },
  },
]
