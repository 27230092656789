import store from '@msk-us/state/store'
import ROUTE from '@msk-us/router/names'

export default async (routeTo, routeFrom, next) => {
  switch (routeTo.name) {
    case ROUTE.ASSESSMENT_LOGIN:
      return next()

    default:
      if (store.getters.user == null) {
        return next({
          name: ROUTE.ASSESSMENT_LOGIN,
          query: routeTo.query,
        })
      }

      return next()
  }
}
