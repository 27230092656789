<template>
  <!-- eslint-disable vue/no-v-html -->
  <div>
    <div
      class="kds-typography-title-medium"
      v-html="$t('b2b_login_label_go_to_signup')"
    />
    <a
      :href="`/b2b/${corporate.key}/login`"
      class="a-link"
      @click="redirectToSignUp"
      v-html="$t('b2b_login_action_go_to_signup')"
    />
  </div>
  <!-- eslint-enable vue/no-v-html -->
</template>

<script setup>
import { computed } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

import ROUTE from '@msk-us/router/names'

const store = useStore()
const router = useRouter()

const corporate = computed(() => store.getters['b2b/corporate'])

const redirectToSignUp = (event) => {
  if (event) {
    event.preventDefault()
  }
  router.push({
    name: ROUTE.B2B_SIGN_UP,
  })
}
</script>
