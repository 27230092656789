const names = {
  RESET_PASSWORD: 'reset-password',
  B2B: 'b2b',
  B2B_PARTNERS: 'b2b-partners',
  UHC_VPT_EMPLOYER_SEARCH: 'b2b-uhc-vpt-employer-search',
  UHC_VPT_INSURANCE_SELECTION: 'b2b-uhc-vpt-insurance-selection',
  UHC_VPT_PRIMER: 'b2b-uhc-vpt-primer',
  B2B_VOUCHER: 'b2b-voucher',
  B2B_SIGN_UP: 'b2b-sign-up',
  B2B_DOWNLOAD_LINK: 'b2b-download-link',
  B2B_SELECT_VERIFICATION_METHOD: 'b2b-select-verification-method',
  B2B_EMAIL: 'b2b-email',
  B2B_LOGIN: 'b2b-login',
  SAML_SSO_INITIATED: 'saml-sso-initiate',
  SAML_SSO_COMPLETED: 'saml-sso-completed',
  SAML_SSO_ERROR: 'saml-sso-error',
  B2B_CORPORATE_ELIGIBILITY_VERIFICATION:
    'b2b-corporate-eligibility-verification',
  B2B_CORPORATE_ELIGIBILITY_LIST_VERIFICATION:
    'b2b-corporate-eligibility-list-verification',
  B2B_PARTICIPATION_ID_VERIFICATION: 'b2b-participation-id-verification',
  B2B_SOLERA_VERIFICATION: 'b2b-solera-verification',
  B2B_UHCHUB_VERIFICATION: 'b2b-uhchub-verification',
  B2B_VERIFY_NOT_POSSIBLE_FOR_EXISTING_USER:
    'b2b-verify-not-possible-for-existing-user',

  B2B_SIMPLE_CHECK_IN: 'b2b-simple-check-in',
  B2B_SIMPLE_PLAN_OUTLINE: 'b2b-simple-plan-outline',
  B2B_SIMPLE_TESTIMONIAL: 'b2b-simple-testimonial',

  B2B_INFORMATION: 'b2b-information',
  B2B_INFORMATION_GENERIC: 'b2b-information-generic',
  B2B_UHCHUB_INIT: 'b2b-uhchub-init',
  B2B_PERSONIFY_HEALTH_INIT: 'b2b-personify-health-init',

  ASSESSMENT_LOGIN: 'assessment-login',
  ASSESSMENT_COMPLETION: 'assessment-completion',

  // assessment selfTestReevaluations
  ASSESSMENT_SELF_TEST_REEVALUATIONS_PAIN_AVERAGE:
    'assessment-self-test-reevaluations-pain-average',
  ASSESSMENT_SELF_TEST_REEVALUATIONS_PAIN_WORST:
    'assessment-self-test-reevaluations-pain-worst',
  ASSESSMENT_SELF_TEST_REEVALUATIONS_PAIN_NOW:
    'assessment-self-test-reevaluations-pain-now',
  ASSESSMENT_SELF_TEST_REEVALUATIONS_PHYSICAL_ACTIVITY_CHORES:
    'assessment-self-test-reevaluations-physical-activity-chores',
  ASSESSMENT_SELF_TEST_REEVALUATIONS_PHYSICAL_ACTIVITY_STAIRS:
    'assessment-self-test-reevaluations-physical-activity-stairs',
  ASSESSMENT_SELF_TEST_REEVALUATIONS_PHYSICAL_ACTIVITY_WALK:
    'assessment-self-test-reevaluations-physical-activity-walk',
  ASSESSMENT_SELF_TEST_REEVALUATIONS_PHYSICAL_ACTIVITY_ERRANDS:
    'assessment-self-test-reevaluations-physical-activity-errands',
  ASSESSMENT_SELF_TEST_REEVALUATIONS_FEAR_OF_MOVEMENT:
    'assessment-self-test-reevaluations-fear-of-movement',
  ASSESSMENT_SELF_TEST_REEVALUATIONS_PRODUCTIVITY:
    'assessment-self-test-reevaluations-productivity',
  ASSESSMENT_SELF_TEST_REEVALUATIONS_CONFIDENCE:
    'assessment-self-test-reevaluations-confidence',
  ASSESSMENT_SELF_TEST_REEVALUATIONS_PHQ_INTEREST:
    'assessment-self-test-reevaluations-phq-interest',
  ASSESSMENT_SELF_TEST_REEVALUATIONS_PHQ_FEELING:
    'assessment-self-test-reevaluations-phq-feeling',
  ASSESSMENT_SELF_TEST_REEVALUATIONS_GAD_NERVOUS:
    'assessment-self-test-reevaluations-gad-nervous',
  ASSESSMENT_SELF_TEST_REEVALUATIONS_GAD_CONTROL:
    'assessment-self-test-reevaluations-gad-control',
  ASSESSMENT_SELF_TEST_REEVALUATIONS_GAD_WORRYING:
    'assessment-self-test-reevaluations-gad-worrying',
  ASSESSMENT_SELF_TEST_REEVALUATIONS_GAD_RELAXING:
    'assessment-self-test-reevaluations-gad-relaxing',
  ASSESSMENT_SELF_TEST_REEVALUATIONS_GAD_RESTLESS:
    'assessment-self-test-reevaluations-gad-restless',
  ASSESSMENT_SELF_TEST_REEVALUATIONS_GAD_IRRITABLE:
    'assessment-self-test-reevaluations-gad-irritable',
  ASSESSMENT_SELF_TEST_REEVALUATIONS_GAD_AFRAID:
    'assessment-self-test-reevaluations-gad-afraid',
  ASSESSMENT_SELF_TEST_REEVALUATIONS_PAST_CONSULTATIONS:
    'assessment-self-test-reevaluations-past-consultations',
  ASSESSMENT_SELF_TEST_REEVALUATIONS_PAST_MEDICATIONS:
    'assessment-self-test-reevaluations-past-medications',
  ASSESSMENT_SELF_TEST_REEVALUATIONS_COMPLETE:
    'assessment-self-test-reevaluations-complete',
}

export const b2b = [
  names.B2B_SELECT_VERIFICATION_METHOD,
  names.B2B_VOUCHER,
  names.B2B_SIGN_UP,
  names.B2B_DOWNLOAD_LINK,
  names.B2B_EMAIL,
  names.B2B_LOGIN,
  names.SAML_SSO_INITIATED,
  names.SAML_SSO_COMPLETED,
  names.SAML_SSO_ERROR,
  names.B2B_CORPORATE_ELIGIBILITY_VERIFICATION,
  names.B2B_CORPORATE_ELIGIBILITY_LIST_VERIFICATION,
  names.B2B_PARTICIPATION_ID_VERIFICATION,
  names.B2B_SOLERA_VERIFICATION,
  names.B2B_UHCHUB_VERIFICATION,
  names.B2B_VERIFY_NOT_POSSIBLE_FOR_EXISTING_USER,
  names.B2B_INFORMATION,
  names.B2B_INFORMATION_GENERIC,
]

export default names
