<template>
  <!-- eslint-disable vue/no-v-html -->
  <div class="already-have-account">
    <div
      class="already-have-account--description"
      v-html="$t('generic_already_have_account')"
    />
    <a
      :href="`/b2b/${corporate.key}/login`"
      class="already-have-account--link"
      @click="redirectToLogin"
      v-html="`&rarr; ${$t('generic_click_to_login')}`"
    />
  </div>
  <!-- eslint-enable vue/no-v-html -->
</template>

<script setup>
import { computed } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

import ROUTE from '@msk-us/router/names'

const store = useStore()
const router = useRouter()

const corporate = computed(() => store.getters['b2b/corporate'])

const redirectToLogin = (event) => {
  if (event) {
    event.preventDefault()
  }
  router.push({
    name: ROUTE.B2B_LOGIN,
    params: {
      corp_name: corporate.value.key,
    },
  })
}
</script>

<style lang="scss">
.already-have-account {
  @include kaia-typography-p2($font-weight-semi-bold);

  &--link {
    color: $ocean;
    text-decoration: none;
  }
}
</style>
