<template>
  <div class="quiz-content__inner physical-activity self-test">
    <div class="self-test__layout">
      <div>
        <div
          class="self-test__description"
          v-html="descriptionText"
        />
        <h3
          class="self-test__header"
          v-html="
            $t('b2b__self_test__physical_activity__msk_us__stairs_header')
          "
        />
        <div
          class="self-test__description"
          v-html="$t('b2b__self_test__physical_activity__msk_us__items_hint')"
        />
        <fieldset class="form-container">
          <div
            v-for="item in items"
            :key="item.key"
            :class="[
              'quiz-radio',
              { 'quiz-radio--checked': item.key === activityStairs },
            ]"
            @click="handleRadioButtonChange(item)"
          >
            <label class="quiz-radio__label kaia-p2-semi-bold">
              {{ $t(item.value) }}
            </label>
            <div class="quiz-radio__layout">
              <custom-radio
                :value="`${item.key}`"
                :checked="item.key === activityStairs"
                data-q-a="physical-activity"
                :data-q-a-value="`${item.key}`"
              />
            </div>
          </div>
        </fieldset>
      </div>
      <div class="interactive-button__layout">
        <BaseButton
          :disabled="interactiveButtonState === 'DISABLED'"
          :loading="interactiveButtonState === 'PROCESSING'"
          :text="$t('generic_action_continue')"
          data-q-a="submit"
          @click="handleNextEvent"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CustomRadio from '@shared/components/CustomRadio.vue'
import BaseButton from '@shared/components/BaseButton.vue'

import physicalActivityDifficulties from '@msk-us/config/physical-activity-difficulties.json'
import { useI18n } from 'petite-vue-i18n'

export default {
  name: 'SelfTestPhysicalActivityStairs',
  components: {
    BaseButton,
    CustomRadio,
  },
  props: {
    handleNextEvent: {
      type: Function,
      default: () => {},
    },
  },
  setup() {
    const { t } = useI18n()
    return { t }
  },
  data() {
    return {
      interactiveButtonState: 'DISABLED',
      items: physicalActivityDifficulties.concat([]),
    }
  },
  computed: {
    ...mapGetters('assessment', ['activityStairs']),
    descriptionText() {
      return this.t('b2b__self_test__physical_activity__msk_us__description', {
        steps: '2/4',
      })
    },
  },
  created() {
    this.interactiveButtonState =
      this.activityStairs > 0 ? 'ACTIVE' : 'DISABLED'
  },
  methods: {
    handleRadioButtonChange(item) {
      this.interactiveButtonState = 'ACTIVE'
      this.$store.commit('assessment/updateActivityStairs', item.key)
    },
  },
}
</script>

<style lang="scss">
@import '@msk-us/views/css/Assessment/SelfTest';

.physical-activity {
  .self-test__layout {
    @media (max-width: $breakpoint-mobile) {
      padding-bottom: 4px;
    }
  }

  .self-test__header {
    margin: 16px 0 32px;
  }

  .self-test__description {
    margin: 0;
  }

  .interactive-button__layout {
    margin: 32px auto 0;
  }

  .form-container {
    margin-top: 8px;
  }
}
</style>
