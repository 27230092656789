import { mapActions } from 'vuex'
import { ROUTE } from '@msk-us/router'

const selfTestReevaluationsNavTable = [
  [
    ROUTE.ASSESSMENT_SELF_TEST_REEVALUATIONS_PAIN_AVERAGE,
    ROUTE.ASSESSMENT_SELF_TEST_REEVALUATIONS_PAIN_WORST,
    ROUTE.ASSESSMENT_SELF_TEST_REEVALUATIONS_PAIN_NOW,
    ROUTE.ASSESSMENT_SELF_TEST_REEVALUATIONS_PHYSICAL_ACTIVITY_CHORES,
    ROUTE.ASSESSMENT_SELF_TEST_REEVALUATIONS_PHYSICAL_ACTIVITY_STAIRS,
    ROUTE.ASSESSMENT_SELF_TEST_REEVALUATIONS_PHYSICAL_ACTIVITY_WALK,
    ROUTE.ASSESSMENT_SELF_TEST_REEVALUATIONS_PHYSICAL_ACTIVITY_ERRANDS,
    ROUTE.ASSESSMENT_SELF_TEST_REEVALUATIONS_FEAR_OF_MOVEMENT,
    ROUTE.ASSESSMENT_SELF_TEST_REEVALUATIONS_PRODUCTIVITY,
    ROUTE.ASSESSMENT_SELF_TEST_REEVALUATIONS_CONFIDENCE,
    ROUTE.ASSESSMENT_SELF_TEST_REEVALUATIONS_PAST_CONSULTATIONS,
    ROUTE.ASSESSMENT_SELF_TEST_REEVALUATIONS_PAST_MEDICATIONS,
    ROUTE.ASSESSMENT_SELF_TEST_REEVALUATIONS_COMPLETE,
  ],
]

function getRouteProperties(instance, currentRouteName, modifier) {
  const navTable = [].concat(...instance.stages) // flatten array of routes;
  const index = navTable.indexOf(currentRouteName) + modifier

  if (typeof index !== 'number' || index < 0 || index >= navTable.length) {
    throw new Error(
      `selfTestReevaluationsNavigation Mixin: Route cannot be determined because the target index is out of bounds. Current route name: ${currentRouteName}, modifier: ${modifier}, target index: ${index}`,
    )
  }

  const routeName = navTable[index]
  return { name: routeName }
}

export default {
  name: 'selfTestReevaluationsNavigation',
  computed: {
    stages() {
      const navTable = selfTestReevaluationsNavTable
      const corporate = this.$store.getters['b2b/corporate']

      if (
        (corporate && corporate.key === 'uhcmedsupp') ||
        sessionStorage.getItem('msk-us::phq-gad-assessment') === '1'
      ) {
        Array.isArray(navTable[0]) &&
          navTable[0].splice(
            10,
            0,
            ROUTE.ASSESSMENT_SELF_TEST_REEVALUATIONS_PHQ_INTEREST,
            ROUTE.ASSESSMENT_SELF_TEST_REEVALUATIONS_PHQ_FEELING,
            ROUTE.ASSESSMENT_SELF_TEST_REEVALUATIONS_GAD_NERVOUS,
            ROUTE.ASSESSMENT_SELF_TEST_REEVALUATIONS_GAD_CONTROL,
            ROUTE.ASSESSMENT_SELF_TEST_REEVALUATIONS_GAD_WORRYING,
            ROUTE.ASSESSMENT_SELF_TEST_REEVALUATIONS_GAD_RELAXING,
            ROUTE.ASSESSMENT_SELF_TEST_REEVALUATIONS_GAD_RESTLESS,
            ROUTE.ASSESSMENT_SELF_TEST_REEVALUATIONS_GAD_IRRITABLE,
            ROUTE.ASSESSMENT_SELF_TEST_REEVALUATIONS_GAD_AFRAID,
          )
      }

      return navTable
    },
    stageProgress() {
      const stepsInStage = this.stages[this.stage].length
      const currentStepInStage = Math.max(
        0,
        this.stages[this.stage].findIndex((step) => step === this.$route.name) +
          1,
      )
      return (currentStepInStage * 100) / stepsInStage
    },
    stageCount() {
      return this.stages.length
    },
    stage() {
      return Math.max(
        0,
        this.stages.findIndex((stage) => stage.includes(this.$route.name)),
      )
    },
  },
  methods: {
    ...mapActions('assessment', ['completeQuestionnaire']),
    async goToNextOrCompleteQuestionnaire() {
      try {
        const routeProperties = getRouteProperties(this, this.$route.name, +1)
        this.$router.push(routeProperties)
      } catch {
        // complete questionnaires;
        await this.completeQuestionnaire({ router: this.$router })
      }
    },
    goToPreviousRoute() {
      const routeProperties = getRouteProperties(this, this.$route.name, -1)
      this.$router.push(routeProperties)
    },
  },
}
