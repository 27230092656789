<template>
  <!-- eslint-disable vue/no-v-html -->
  <ResponsiveLayout>
    <div class="kds-main-wrapper">
      <CorporateHeader />
      <div class="kds-content-block">
        <p
          class="kds-typography-display-small"
          v-html="$t('b2b__verification__eligibility__header')"
        />
        <p
          v-if="showVerificationSection"
          class="kds-typography-paragraph-large"
          v-html="
            $t('b2b__verification__description', {
              corporate: corporate.title,
            })
          "
        />
      </div>

      <BaseBanner
        v-if="showAlreadyUsedBanner"
        variant="info"
        :title="$t('b2b__verification__already_used_error_title')"
        scroll-into-view
      >
        <p
          class="kds-typography-paragraph-medium"
          v-html="$t('b2b__verification__already_used_error_description')"
        />
        <template #actions>
          <BaseButton
            size="small"
            @click="goToAppLogin"
          >
            {{ $t('b2b__verification__already_used_error_cta_open_app') }}
          </BaseButton>

          <GenericSupportModal
            :additional-data="corporateEligibilityData"
            :subject="$t('b2b__support_modal_subject__login_support')"
            @submit-success="handleSupportTicketCreated"
          />
        </template>
      </BaseBanner>

      <BaseBanner
        v-if="showNotEligibleBanner"
        variant="warning"
        :title="$t('b2b__request_support_banner_title')"
        data-qa="request-support-base-banner"
        scroll-into-view
      >
        <p class="kds-typography-paragraph-medium">
          {{ $t('b2b__request_support_banner_description') }}
        </p>
        <NotEligibleSupportModal
          :additional-data="corporateEligibilityData"
          :corporate="corporate"
          @submit-success="handleSupportTicketCreated"
        >
          <ul>
            <li
              v-for="{ title, key } in showFallbackFields
                ? formFields
                : nonFallbackFields"
              :key="key"
              class="request-support-modal__additional_data--item"
            >
              {{ `${title}: ${corporateEligibilityData?.[key]}` }}
            </li>
          </ul>
        </NotEligibleSupportModal>
      </BaseBanner>

      <BaseBanner
        v-if="showGenericErrorBanner"
        variant="warning"
        scroll-into-view
      >
        <p
          class="kds-typography-title-medium"
          v-html="
            $t(
              'b2b_corporate_eligibility_data_not_valid_with_support_email_and_helpscout',
              { VUE_APP_SUPPORT_EMAIL: supportEmail.value },
            )
          "
        />
      </BaseBanner>

      <form
        id="eligibility_check_form"
        class="kds-content-block"
      >
        <template
          v-for="field in nonFallbackFields"
          :key="field.key"
        >
          <TextField
            v-if="field.type === 'string' || field.type === 'email'"
            v-bind="{ ...fieldAttrs[field.key], ...field.attrs }"
          />

          <DateOfBirthField
            v-if="field.type === 'dob'"
            v-bind="{ ...fieldAttrs[field.key], ...field.attrs }"
            :lang="lang"
          />
        </template>

        <template v-if="showFallbackFields">
          <div
            v-html="
              fallbackFields.length === 1
                ? $t('b2b_corporate_eligibility_data_fallback_single', {
                    fallbackFieldName: fallbackFields[0].title,
                  })
                : $t(
                    'b2b_corporate_eligibility_data_multiple_matches_more_info',
                  )
            "
          />

          <template
            v-for="field in fallbackFields"
            :key="field.key"
          >
            <TextField
              v-if="field.type === 'string' || field.type === 'email'"
              v-bind="{ ...fieldAttrs[field.key], ...field.attrs }"
            />

            <DateOfBirthField
              v-if="field.type === 'dob'"
              v-bind="{ ...fieldAttrs[field.key], ...field.attrs }"
              :lang="lang"
            />
          </template>
        </template>

        <!-- have the main CTA above the secondary signup option -->
        <BaseButton
          v-if="showSamlSSOSignupOption"
          v-bind="submitAttrs"
          data-qa="submit"
          :text="$t('generic_check_eligibility')"
        />
      </form>

      <BaseBanner
        v-if="showSamlSSOSignupOption && !isMigratedToPersonifyHealth"
        variant="info"
        class="saml-sso-banner"
        :title="
          $t('b2b__corporate_eligibility__label__signup_with_virginpulse')
        "
        :description="
          $t('b2b__corporate_eligibility__description__signup_with_virginpulse')
        "
      >
        <template #actions>
          <BaseButton
            size="small"
            class="saml-sso-banner__actions"
            @click="redirectToSamlSSO"
          >
            <div class="saml-sso-banner__actions--button-content">
              <span class="kds-typography-paragraph-large">
                {{ $t('b2b__button__signup_with_generic') }}
              </span>
              <img
                src="@shared/assets/img/b2b/virginpulse.png"
                alt="virginpulse"
                class="saml-sso-banner__actions--button-content--image"
              />
            </div>
          </BaseButton>
        </template>
      </BaseBanner>

      <BaseBanner
        v-if="showSamlSSOSignupOption && isMigratedToPersonifyHealth"
        variant="info"
        class="saml-sso-banner"
        :title="
          $t('b2b__corporate_eligibility__label__signup_with_personify_health')
        "
        :description="
          $t(
            'b2b__corporate_eligibility__description__signup_with_personify_health',
          )
        "
      >
        <template #actions>
          <BaseButton
            size="small"
            class="saml-sso-banner__actions"
            @click="redirectToSamlSSO"
          >
            <div class="saml-sso-banner__actions--button-content">
              <span class="kds-typography-paragraph-large">
                {{ $t('b2b__button__signup_with_generic') }}
              </span>
              <img
                src="@shared/assets/img/b2b/personify-health.svg"
                alt="personify health"
                class="saml-sso-banner__actions--button-content--image"
              />
            </div>
          </BaseButton>
        </template>
      </BaseBanner>
    </div>
    <template
      v-if="!showSamlSSOSignupOption"
      #actions
    >
      <BaseButton
        v-bind="submitAttrs"
        data-qa="submit"
        form="eligibility_check_form"
        :text="$t('generic_check_eligibility')"
      />
    </template>
  </ResponsiveLayout>
  <!-- eslint-enable vue/no-v-html -->
</template>

<script setup>
import { ref, computed, watch, watchEffect, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { isFirstNameKey, isLastNameKey, logI } from '@shared/utils'

import Tracker from '@shared/Tracker'

import useForm from '@shared/composables/useForm.js'

import assembleKaiaAppDeepLink from '@shared/helpers/assembleKaiaAppDeepLink.js'

import ROUTE from '@msk-us/router/names'

import NotEligibleSupportModal from '@msk-us/components/NotEligibleSupportModal.vue'
import GenericSupportModal from '@msk-us/components/GenericSupportModal.vue'

import BaseBanner from '@shared/components/BaseBanner.vue'
import BaseButton from '@shared/components/BaseButton.vue'
import DateOfBirthField from '@shared/components/form/DateOfBirthField.vue'
import TextField from '@shared/components/form/TextField.vue'
import ResponsiveLayout from '@shared/components/ResponsiveLayout.vue'
import CorporateHeader from '@shared/components/CorporateHeader.vue'
import personifyHealthMigration from '@msk-us/config/personify-health-migration.js'

const store = useStore()
const route = useRoute()
const router = useRouter()

const config = ref({})

const showAlreadyUsedBanner = ref(false)
const showNotEligibleBanner = ref(false)
const showGenericErrorBanner = ref(false)
const showFallbackFields = ref(false)

const lang = computed(() => store.getters['lang'])

watch(lang, async () => {
  config.value = await ensureCorporateEligibilityConfig()
})

const corporate = computed(() => store.getters['b2b/corporate'])

const showSamlSSOSignupOption = computed(
  () => corporate.value.virginPulseSponsorId > 0,
)

const isMigratedToPersonifyHealth = computed(() =>
  personifyHealthMigration.isMigrated(corporate.value.key),
)

const corporateEligibilityData = computed(
  () => store.getters['b2b/formDataCorporateEligibilityData'],
)

const verificationParams = computed(
  () => store.getters['b2b/verificationParams'],
)
const utmData = computed(() => store.getters['b2b/utmData'])

const kaiaSubmissionTrackEvent = (success, errorMessage, eventObj) => {
  const custom_payload = {
    search_success: success,
    corporate_id: corporate.value.id,
    corporate_name: corporate.value.title,
    corporate_type: corporate.value.corporateTypes.toString(),
    ...(corporate.value.channel
      ? { corporate_channel: corporate.value.channel }
      : null),
    ...(errorMessage ? { error_message: errorMessage } : null),
    ...(utmData.value.medium ? { utm_medium: utmData.value.medium } : null),
    ...(utmData.value.source ? { utm_source: utmData.value.source } : null),
    ...(utmData.value.campaign
      ? { utm_campaign: utmData.value.campaign }
      : null),
    ...(utmData.value.content ? { utm_content: utmData.value.content } : null),
  }

  Tracker.trackKaiaEvent(eventObj, {
    custom_payload,
  })
}

const supportEmail = computed(() => import.meta.env.VITE_SUPPORT_EMAIL)

const formFields = computed(() => {
  return (
    config?.value?.fields
      ?.filter((field) => !field.is_fallback || showFallbackFields.value)
      ?.map((field) => {
        field.attrs = {}
        if (isFirstNameKey(field.key)) field.attrs.autocomplete = 'given-name'
        if (isLastNameKey(field.key)) field.attrs.autocomplete = 'family-name'
        if (field.type === 'email') field.attrs.type = 'email'
        if (field.type === 'email') field.attrs.autocomplete = 'email'

        field.attrs.label = field.title
        field.attrs.dataQa = field.key

        return field
      }) ?? []
  )
})

const nonFallbackFields = computed(() =>
  formFields.value.filter((field) => !field.is_fallback),
)
const fallbackFields = computed(() =>
  formFields.value.filter((field) => field.is_fallback),
)

const showVerificationSection = computed(
  () => corporate.value.key !== 'uhcmedsupp',
)

const ensureCorporateEligibilityConfig = async () => {
  const corporateEligibilityConfig = await store.dispatch(
    'b2b/getCorporateEligibilityConfig',
    {
      corporateKey: corporate.value.key,
      lang: lang.value,
    },
  )

  if (
    !corporateEligibilityConfig.fields ||
    corporateEligibilityConfig.fields.length === 0
  ) {
    throw new Error(
      `The eligibility config of the corporate ${corporate.value.title} does not contain any fields`,
    )
  }

  return corporateEligibilityConfig
}

const eventObj = {
  event_name: 'c.lfcycl.submit_eligibility_verification',
  app_area: 'onboarding',
  action: 'submit',
  object_type: 'eligibility_verification',
  source: 'client_browser',
  screen_name: 'onb_eligibility_verification',
}

const goToAppLogin = async () => {
  const deepLink = await assembleKaiaAppDeepLink('goto/screen', {
    target: 'login',
  })
  window.location.assign(deepLink)
}

const redirectToNext = () => {
  router.push({
    name: ROUTE.B2B_SIGN_UP,
    params: {
      corp_name: store.getters['b2b/corporateEligibility'].corporate_key,
    },
  })
}

const redirectToSamlSSO = () => {
  router.push({
    name: ROUTE.SAML_SSO_INITIATED,
    params: {
      corp_name: corporate.value.key,
    },
  })
}

const formConfig = computed(() =>
  Object.fromEntries(
    formFields.value.map((field) => {
      const validations = ['required']
      if (field.type === 'dob') validations.push('dob')
      if (field.type === 'email') validations.push('email')
      return [field.key, { validations }]
    }),
  ),
)

const form = ref({
  ...corporateEligibilityData.value,
})

watchEffect(() => {
  store.commit('b2b/setFormDataCorporateEligibilityData', {
    ...Object.fromEntries(
      Object.entries(form.value).map(([key, value]) => [key, value.trim()]),
    ),
  })
})

const { fieldAttrs, submitAttrs, submit } = useForm(form, formConfig, {
  onSubmit: async () => {
    showAlreadyUsedBanner.value = false
    showNotEligibleBanner.value = false
    showGenericErrorBanner.value = false

    Tracker.trackKaiaEvent(
      {
        event_name: 'c.lfcycl.select_button',
        app_area: 'onboarding',
        action: 'select',
        object_type: 'button',
        source: 'client_browser',
        screen_name: 'onb_eligibility_verification',
      },
      {
        button_label: 'continue',
      },
    )
    await store.dispatch('b2b/checkCorporateEligibility', {
      corporate_key: corporate.value.key,
      ...Object.fromEntries(
        Object.entries(corporateEligibilityData.value).filter(
          ([, value]) => !!value,
        ),
      ),
    })
  },
  onSuccess: async () => {
    kaiaSubmissionTrackEvent(true, null, eventObj)
    logI('verification attempt', 'succeeded')

    redirectToNext()
  },
  onError: (error) => {
    kaiaSubmissionTrackEvent(false, error, eventObj)

    logI('corporate eligibility check failed', error)

    let errors = []
    if (error?.response?.status === 403) {
      errors =
        (error.response.data &&
          Array.isArray(error.response.data.errors) &&
          error.response.data.errors) ||
        errors

      if (errors.includes('MULTIPLE_MATCHES_BUT_UNIQUE_MATCH_REQUIRED')) {
        showFallbackFields.value = true
      } else if (errors.includes('ALREADY_USED')) {
        showAlreadyUsedBanner.value = true
      } else if (errors.includes('NOT_ELIGIBLE')) {
        showNotEligibleBanner.value = true
      }
      logI('verification attempt', `failed ${errors}`)
    }

    if (errors.length === 0) {
      showGenericErrorBanner.value = true
      logI('verification attempt', `failed ${error}`)
      throw error
    }
  },
})

const handleSupportTicketCreated = () => {
  showAlreadyUsedBanner.value = false
  showNotEligibleBanner.value = false
}

onMounted(async () => {
  config.value = await ensureCorporateEligibilityConfig()

  config.value.fields.forEach((field) => {
    const initValue =
      route.query[field.key] || verificationParams.value[field.key]

    if (initValue) {
      form.value[field.key] = initValue
    }
  })

  const shouldSubmit = formFields.value.every(({ key }) => !!form?.value?.[key])

  if (shouldSubmit) {
    submit()
  }
})
</script>

<style lang="scss">
:root {
  --base-banner--margin: 0;
}

.saml-sso-banner {
  &__actions {
    @include margin(auto, right);

    &--button-content {
      @include flex-container(row, center, sm);

      &--image {
        height: 25px;
      }
    }
  }
}
</style>
